//Sass variable
@use "_variables" as *;

// * {
//   outline: 1px solid red;
// }
body {
  background-color: $background-color;
  color: $primary-color;
}

header {
  background-color: $primary-color;
  color: $background-color;
  position: sticky;
  top: 0;
  font-size: 2rem;
  z-index: 2;
  @media only all and (max-width: 950px) {
    font-size: 1.2em;
  }

  .header-links {
    display: flex;
    gap: 24px;
    font-size: 0.75em;
    @media only all and (max-width: 950px) {
      gap: 16px;
    }
  }

  .nav-header {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px 0 100px;

    @media only all and (max-width: 950px) {
      padding: 0 24px 0 24px;
    }
  }
}

a {
  text-decoration: none;
  color: $background-color;
}

.home-section {
  justify-content: center;
  // background-color: cornflowerblue;
}

.intro-card {
  display: flex;
  flex-direction: column;
  background: $secondary-color;
  margin: 0px auto;
  width: 60%;
  padding: 0 5% 0px 5%;
  height: auto;
  border-radius: 0 0 10px 10px;
  font-style: normal;
  box-shadow: $box-shadow;
  box-sizing: content-box;

  @media only all and (max-width: 950px) {
    width: auto;
    height: 250px;
    justify-content: center;
    padding: 0 16px 0px 0;
  }
}

h1 {
  font-size: 1.75em;
  font-weight: 600;
  margin: 0;
  padding-top: 24px;
  text-align: right;
  @media only all and (max-width: 950px) {
    font-size: 1.4em;
  }
  @media only all and (max-width: 635px) {
    font-size: 1em;
    padding: 16px 24px 24px 24px;
    text-align: right;
  }
}

p {
  font-size: 1.3em;
  font-style: thin;
  margin: 0;
  padding-top: 20px;
  text-align: right;
  color: $tertiary-color;
  word-wrap: break-word;
  @media only all and (max-width: 950px) {
    font-size: 0.9em;
  }
  @media only all and (max-width: 950px) {
    font-size: 0.9em;
    padding: 0 24px 56px 0;
  }
}

.img-headshot {
  display: flex;
  height: 100%;
  width: auto;
  object-fit: contain;
  padding: 0 0 0 20px;
  box-sizing: border-box;

  @media only all and (max-width: 950px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  .face {
    width: auto;
    height: fit-content;
    overflow: auto;

    // box-sizing: border-box;

    @media only all and (max-width: 950px) {
      max-height: 00px;
      max-width: 00px;
    }
  }

  .button-cta-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .button-cta {
      width: auto;
      padding: 8px;
      background-color: white;
      font-family: poppins;
      font-size: 1.5em;
      border-radius: 4px;
      border: solid;
      color: $primary-color;
      @media only all and (max-width: 950px) {
        font-size: 1em;
      }
    }
    a.button-cta:hover {
      background-color: rgb(155, 220, 237);
    }
  }
}

//PROJECT NAV HEADER
.nav-project-header {
  display: flex;
  padding: 100px 0 0 0;
  font-size: 3em;
  margin: 0px auto;
  width: 70%;
  @media only all and (max-width: 950px) {
    width: auto;
    padding: 0 0 0px 16px;
  }

  // background-color: aqua;
  //project. header
  h1 {
    padding-right: 200px;
    @media only all and (max-width: 950px) {
      width: 100%;
      padding: 0 0 0px 0;
      margin-top: 72px;
    }
  }
}
.project-links {
  display: flex;
  gap: 24px;
}

//PROJECTS CONTAINER
.project-section {
  // background-color: cadetblue;
}

.project-card {
  display: flex;
  background: $secondary-color;
  margin: 72px auto;

  height: 400px;
  width: 70%;
  border-radius: 10px 10px 10px 10px;
  font-style: normal;
  box-shadow: $box-shadow;
  @media only all and (max-width: 950px) {
    width: 100%;
    padding: 0 0 0px 0;
    flex-direction: column;
    height: 600px;
  }
}
.card-image-container {
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 0 10px 10px 0;
  object-fit: contain;
  padding: 0 0 0 20px;
  box-sizing: border-box;
  @media only all and (max-width: 950px) {
    border-radius: 0 0px 10px 10px;
    padding: 0 0 0 0px;
  }

  // background-color: hotpink;
  .project-image {
    box-sizing: border-box;
    height: 100%;
    border-color: red;
    border-width: 4px;
    width: 100%; //auto
    object-fit: cover; //added
    overflow: clip;
    border-radius: 0 10px 10px 0;
    
    @media only all and (max-width: 950px) {
      padding: 0 0 0px 0;
    }
  }
}

.project-description {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 0 8px 0 24px;
  // background-color: blueviolet;
  gap: 16px;
  word-break: normal;
  font-size: 0.9em;
  @media only all and (max-width: 950px) {
    width: auto;
    padding: 0 24px 0 24px;
  }
}
.project-card-title {
  text-align: left;
  padding: 16px 0 0px 0;
  font-size: 1.4em;
  font-weight: 700;
  @media only all and (max-width: 950px) {
    width: 100%;
    padding: 16px 0 0px 0;
  }
}

.project-type-paragraph {
  font-weight: 300;
  @media only all and (max-width: 950px) {
    font-weight: 200;
    font-size: 0.8em;
  }
}
.project-desc-paragraph {
  font-weight: 300;
  @media only all and (max-width: 950px) {
  }
}
.project-desc-paragraph {
  font-weight: 300;
  @media only all and (max-width: 950px) {
  }
}
.project-technology-container {
  flex-grow: 1;
  font-weight: 300;
}

.project-technology-title {
  font-weight: 700;
  padding-top: 8px;
}

.project-button-container {
  display: flex;
  justify-content: center;
  padding: 0 0 24px 0;
  gap: 36px;
  @media only all and (max-width: 950px) {
    padding: 8px 0 16px 0;
    justify-content: left;
  }
}

.button-project-links {
  width: auto;
  padding: 8px;
  background-color: white;
  font-family: poppins;
  font-size: 1.5em;
  border-radius: 4px;
  border: solid;
  color: $primary-color;
}

///////////////
/// project details
///

.project-details {
  display: flex;
  justify-content: left;
  h1 {
    align-items: left;
  }
}

////
/// ABOUT
////
.about-section {
  h1 {
    padding-top: 100px;
    text-align: left;
    margin: 0px auto;
    width: 70%;
    font-size: 3em;
    @media only all and (max-width: 950px) {
      width: auto;
      padding: 0px 0 0px 16px;
      font-size: 3em;
    }
  }
}
.about-card {
  display: flex;
  background: $secondary-color;
  margin: 24px auto;
  padding: 0 5% 0 5%;
  height: auto;
  width: 60%;
  border-radius: 10px 10px 10px 10px;
  font-style: normal;
  box-shadow: $box-shadow;
  column-gap: 24px;
  @media only all and (max-width: 950px) {
    width: 100%;
    flex-direction: column-reverse;
    padding: 0 0 0px 0;
  }

  .about-info {
    width: 50%;
    padding: 16px 0px 16px 0px;
    @media only all and (max-width: 950px) {
      width: 100%;
      padding: 0px 0 0px 0;
    }
  }
  .bio-top {
    text-align: left;
    color: black;
    background-color: $background-color;
    padding: 8px;
    @media only all and (max-width: 950px) {
      width: auto;
      padding: 24px;
      font-weight: 500;
      font-size: 1em;
      text-align: left;
    }
  }
  .life-outside {
    padding-top: 10%;
    text-align: center;
    font-weight: 500;
    @media only all and (max-width: 950px) {
      padding-top: 16px;
    }
  }
  .bio {
    padding-top: 0;
    text-align: left;
    color: black;
    font-weight: 300;
    padding: 8px 8px 8px 8px;
    font-size: 1em;
    @media only all and (max-width: 950px) {
      height: auto;
      padding: 8px 16px 8px 16px;
    }
  }
  .contact {
    margin-top: 16px;
    padding: 8px;
    text-align: center;
    border: solid;
    font-weight: 700;
  }
  .technology-title {
    text-align: center;
  }
  .tech-stack {
    width: 50%;
    padding: 16px 0px 16px 0px;
    @media only all and (max-width: 950px) {
      width: 100%;
      padding: 0px 0 0px 0;
    }

    .icon-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 24px;

      .icon-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        height: auto;
        padding: 14px;
        font-weight: 200;
        @media only all and (max-width: 950px) {
          width: 50px;
          font-size: 0.8em;
        }
      }
    }
  }
}
.footer {
  background-color: black;
  color: white;
  width: 100%;
  height: auto;
  padding: 16px 0;
  text-align: center;
}

a.button-project-links:hover {
  background-color: rgb(155, 220, 237);
}

@media only all and (min-width: 1px) {
}
